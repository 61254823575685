.radio-type-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    label {
        flex-grow: 1;
        width: 100%;
    }

    .form-check {
        margin-right: 2rem;

        input {
            margin-right: 0.5rem;
            transform: scale(1.5);
        }
    }

    .help-block {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        flex-grow: 1;
        width: 100%;
    }
}

$app-body: #f9fbfd;
$app-dark: #f0f3f9;

.breadcrumbs {
    background-color: $app-body;
    color: #000;
    display: flex;
    font-size: 14px;
    position: relative;
    border: 1px solid $app-dark;
    border-radius: 0.25rem;

    .breadcrumb-item {
        padding-left: 30px;

        &:first-child {
            padding-left: 10px;
        }

        .number {
            display: block;
            background-color: $app-dark;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            padding: 4px 10px;
            float: left;
            margin: 13px 0 0 10px;
        }

        .label {
            display: block;
            padding: 17px 0 17px 10px;
            float: left;
        }

        &::after {
            content: '';
            display: block;
            width: 35px;
            height: 35px;
            transform: rotate(130deg) skew(-10deg);
            float: right;
            position: relative;
            top: 10px;
            left: 19px;
            border-top: 1px solid darken($app-dark, 10%);
            border-left: 1px solid darken($app-dark, 10%);
            background-color: $app-body;
        }

        &::before {
            content: none;
        }

        &.current,
        &.current::after {
            //ovo  je boja kada je trenutni korak
            background-color: #42ba96;
            color: #fff;
        }

        &.current .number {
            background-color: #389e7f;
        }
    }
}

$menu-size: 280px;

.app-body {
    .sidebar {
        &.sidebar-pills {
            flex: 0 0 $menu-size;
        }
    }
}

.sidebar .nav {
    flex-direction: column;
    min-height: 100%;
    padding: 0;
    width: calc($menu-size - 25px);
}

.sidebar .sidebar-nav, .sidebar .sidebar-scroll {
    flex: 1;
    position: relative;
    width: calc($menu-size - 25px);
}

html:not([dir=rtl]) .sidebar {
    margin-left: calc($menu-size * -1);
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.info {
    background-color: #d1ecf1; /* Light blue background for info */
}

.warning {
    background-color: #fff3cd; /* Light yellow background for warning */
}

.success {
    background-color: #d4edda; /* Light green background for success */
}

.danger {
    background-color: #f8d7da; /* Light red background for danger */
}

.read {
    opacity: 0.3;
}

.read{
    &:hover {
        opacity: 1!important;
    }
}

.d-print-none {
    &.with-border {
        display: flex;
        gap: 20px;
    }
}

@keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0.3;}
    100% {opacity: 1;}
}

.blinking {
    animation: blink 1s linear infinite;
}
